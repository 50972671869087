import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { VendorForm } from './VendorForm'


export default function VendorFormPopup({ vendor, open, handleClose, handleSubmit }) {

    return (vendor ?
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Edit Vendor</DialogTitle>
            <DialogContent>
                <VendorForm
                    vendor={vendor}
                    callback={handleSubmit}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog> :
        <Dialog open={open} onClose={handleClose} >
            <DialogTitle>Add a new Vendor</DialogTitle>
            <DialogContent>
                <VendorForm
                    vendor={null}
                    callback={handleSubmit}
                    handleClose={handleClose}
                />
            </DialogContent>
        </Dialog>
    );
}