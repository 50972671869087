import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const UploadList = ({ connectedAccounts, uploadedFiles }) => {

    let numConnectedAccounts = connectedAccounts.length
    let numUploadedFiles = uploadedFiles.length

    if (numConnectedAccounts !== 0 || uploadedFiles.length !== 0) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: 'fit-content',
                    background: '#f2f2f8',
                    mt: 1,
                    paddingX: 2,
                    boxSizing: 'border-box'
                }}
            >
                <List>
                    {connectedAccounts.map((account, i) =>
                        <ListItem divider={numUploadedFiles === 0 && i === numConnectedAccounts - 1 ? false : true} disable>
                            <ListItemIcon>
                                <ModeStandbyIcon color='success' />
                            </ListItemIcon>
                            <ListItemText primary={account.institution_name} />
                        </ListItem>
                    )}
                    {uploadedFiles.map((file, i) =>
                        <ListItem divider={i === numUploadedFiles - 1 ? false : true} disable>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary={file.path} />
                        </ListItem>
                    )}
                </List >
            </Box>
        )
    } else {
        return null
    }
}