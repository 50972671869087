import { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { maskAccountNumber } from '@lendica/utils';

export default function VendorSelect({ handleSelect, selectedVendor }) {
    const [value, setValue] = useState(selectedVendor);
    const [vendors, setVendors] = useState([]);

    const setVendor = vendor => {
        handleSelect(vendor);
    };

    useEffect(() => {
        (async () => {
            let vendorList = (await axios.get('/company/vendors')).data;
            setVendors(vendorList);
        })();
        if (selectedVendor) {
            setValue(selectedVendor);
        }
    }, [value, selectedVendor]);

    // let helperText = '';

    // if (value && value.bank_account) {
    //     let bankAccountNum = value.bank_account;
    //     const mask = '*';
    //     const maskedAccountNum = mask.repeat(4) + value.bank_account.slice(-4);
    //     helperText = `${value.bank_name} - ${maskedAccountNum}`;
    // }

    return (
        <Fragment>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    setVendor(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={vendors}
                getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    return option.company_name || '';
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => <li {...props}>{option.company_name}</li>}
                fullWidth
                disablePortal
                size="small"
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Vendor"
                        helperText={`${value?.bank_name} - ${maskAccountNumber(
                            value?.bank_account || ''
                        )}`}
                    />
                )}
            />
        </Fragment>
    );
}
