export interface Approval {
    id: string;
    active: boolean;
    contract_start_date: string;
    term_length_in_days: number;
    qualified_amount: number;
    max_days_outstanding: number;
    origination_fee_percentage: number;
    advance_rate: number;
    funds_available: number;
    funds_in_use: number;
    number_of_active_deals: number;
    total_payoff_amount: number;
    total_in_process_amount: number;
    total_early_payoff_amount: number;
    total_savings_from_payoff: number;
    company: string;
    company_name: string;
    product_name: string;
}

export interface Company {
    id: string;
    partner_company_uuid: string | null;
    partner_company_id?: string | null;
    status: CompanyStatus;
    company_name: string;
    company_address: string;
    partner_name: string | null;
    fundnow_status: ProductStatus;
    paylater_status: ProductStatus;
    drawdown_status: ProductStatus;
    first_name: string | null;
    last_name: string | null;
    phone_number: string | null;
    email: string | null;
    bank_name: string | null;
    bank_routing?: string | null;
    bank_account?: string | null;
}

export enum CompanyStatus {
    Lead,
    Pending,
    Approved,
    Rejected
}
export interface Deal {
    id: string;
    invoice_number: string;
    filename: string | null;
    funded_date: string;
    first_payment_date: string;
    payment_frequency: string;
    number_of_payments: number;
    gross_funded_amount: number;
    origination_fee_amount: number;
    advance_amount: number;
    expected_rate: number;
    disbursement_to_vendor: number;
    disbursement_to_company: number;
    payback_amount: number;
    total_remaining: number;
    total_collected: number;
    periodic_collection_from_company: number;
    total_principal_collected: number;
    total_principal_remaining: number;
    disbursement_to_customer: number;
    collection_from_vendor: number;
    collection_from_company: number;
    collection_from_customer: number;
    status: DealStatus;
    deal_status: string;
    company: string;
    vendor: string | null;
    approval: string;
    company_name: string;
    vendor_name: string;
    product_name: string;
    selected_offer: string | null;
}

export interface Vendor {
    id?: string;
    bank_account?: string;
    bank_name?: string;
    bank_routing?: string;
    company?: string;
    company_address?: string;
    company_name?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
    partner_company_uuid?: string;
    partner_company_id?: string;
    partner_name?: string;
    status?: number;
    fundnow_status?: boolean;
    paylater_status?: boolean;
    drawdown_status?: boolean;
}

export interface Customer {
    id?: string;
    bank_account?: string;
    bank_name?: string;
    bank_routing?: string;
    company?: string;
    company_address?: string;
    company_name?: string;
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
    partner_company_uuid?: string;
    partner_company_id?: string;
    partner_name?: string;
    status?: number;
    fundnow_status?: boolean;
    paylater_status?: boolean;
    drawdown_status?: boolean;
}

export enum DealStatus {
    Available,
    Submitted,
    InProgress,
    Overdue,
    Complete,
    Invalid,
}

export enum ProductStatus {
    Inactive,
    Active,
    Paused,
}