import axios from 'axios';

const apiKey = 'AIzaSyDpaTbeSIERqaAMp2QhGK--Bpx2tmufWWU';

export const register = async (params: {
    email: string,
    password: string,
    company_name: string
}): Promise<any> => {
    const response = await axios.post('/auth/register', params)
    return response.data
}

export const getCompany = async (): Promise<any> => {
    const response = await axios.get('/company/summary');
    return response.data;
}

export const getApplication = async (): Promise<any> => {
    const response = await axios.get('/application');
    return response.data;
}

export const postApplication = async (params: any): Promise<any> => {
    const response = await axios.post('/application', params);
    return response.data;
}

// SHOPIFY

export const getBillDetails = async (bill_id: string): Promise<any> => {
    const response = await axios.get(`/company/bills?id=${bill_id}`)
    return response.data
}

export const getShippingMethods = async (bill_id: string): Promise<any> => {
    const response = await axios.get(`/paylater/shopify/shipping?bill_id=${bill_id}`)
    return response.data
}

export const postShippingMethod = async (bill_id: string, shipping_method: any): Promise<any> => {
    console.log(shipping_method)
    const response = await axios.post(`/paylater/shopify/shipping?bill_id=${bill_id}`, shipping_method)
    return response.data
}

export const postShippingDetails = async (bill_id: string, shipping_details: any): Promise<any> => {
    const response = await axios.post(`/company/bills?id=${bill_id}`, shipping_details)
    return response.data
}

export const getDataConnections = async () => {
    const response = await axios.get('/company/connections')
    return response.data
}

export const getPlaidToken = async (baseURL: string) => {
    const response = await axios.get(baseURL + '/oauth/plaid/get-link-token')
    return response.data.link_token
}

export const postPlaidToken = async (token: string, company_id: string, baseURL: string) => {
    const response = await axios.post(baseURL + '/oauth/plaid/get_access_token', { public_token: token, company_id: company_id })
    return response.data
}

export const geocodeAddress = async (place_id: string) => {
    const axiosInstance = axios.create()
    const response = await axiosInstance.get(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place_id}&key=${apiKey}`)
    return response.data.results[0]
}