import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StatusPage } from './StatusPage';
import { useAppDataContext } from './AppData';
import { matchHostname } from '@lendica/utils';

export const ShopifyApproved = () => {
    return (
        <StatusPage
            title="Congrats! You are ready to go."
            subtitle="You can track your payments and view reports in the portal."
            duration={25}
            actionLabel="Go Now"
            action={() => {
                window?.location?.ancestorOrigins?.length > 0
                    ? (window.parent.location = `https://portal.${matchHostname()}/`)
                    : (window.location = `https://portal.${matchHostname()}/`);
            }}
        />
    );
};

export const ShopifyPending = () => {
    const { billId } = useParams();
    const { bill, loadBill } = useAppDataContext();

    useEffect(() => {
        if (!bill) {
            loadBill(billId);
        }
    }, []);

    return (
        <StatusPage
            title="Your application is being processed."
            subtitle="Typical response time is 24-48 hours. You can track your progress in the portal."
            duration={20}
            actionLabel="Go To Portal"
            action={() => {
                window?.location?.ancestorOrigins?.length > 0
                    ? (window.parent.location = `https://portal.${matchHostname()}/`)
                    : (window.location = `https://portal.${matchHostname()}/`);
            }}
            secondaryActionLabel={bill.web_url ? 'Checkout w/o PayLater' : null}
            secondaryAction={
                bill.web_url
                    ? () => {
                          window?.location?.ancestorOrigins?.length > 0
                              ? (window.parent.location = bill?.web_url)
                              : (window.location = bill?.web_url);
                      }
                    : null
            }
        />
    );
};

export const ShopifyRejected = () => {
    const { billId } = useParams();
    const { bill, loadBill } = useAppDataContext();

    useEffect(() => {
        if (!bill) {
            loadBill(billId);
        }
    }, []);

    return (
        <StatusPage
            title="Sorry, your application was not approved this time."
            subtitle="You may complete checkout without Lendica PayLater."
            // duration={20}
            actionLabel={bill.web_url ? 'Checkout w/o PayLater' : null}
            action={
                bill.web_url
                    ? () => {
                          window?.location?.ancestorOrigins?.length > 0
                              ? (window.parent.location = bill?.web_url)
                              : (window.location = bill?.web_url);
                      }
                    : null
            }
        />
    );
};

export const ExceedsLimit = () => {
    const { billId } = useParams();
    const { bill, loadBill } = useAppDataContext();

    useEffect(() => {
        if (!bill) {
            loadBill(billId);
        }
    }, []);

    return (
        <StatusPage
            title="Sorry, you've reached the credit limit"
            subtitle="We couldn't approve the deal at this time. You may complete checkout without Lendica PayLater."
            actionLabel="Go to Portal"
            action={() => {
                window?.location?.ancestorOrigins?.length > 0
                    ? (window.parent.location = `https://portal.${matchHostname()}/`)
                    : (window.location = `https://portal.${matchHostname()}/`);
            }}
            secondaryActionLabel={bill.web_url ? 'Checkout w/o PayLater' : null}
            secondaryAction={
                bill.web_url
                    ? () => {
                          window?.location?.ancestorOrigins?.length > 0
                              ? (window.parent.location = bill?.web_url)
                              : (window.location = bill?.web_url);
                      }
                    : null
            }
        />
    );
};
