import { Box, Backdrop, CircularProgress, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { createContext, useContext, useState } from 'react';

import { Logo, PayLaterLogo } from '@lendica/components';

import { useAppDataContext } from './AppData';

const AppContainerContext = createContext();

export const useAppContainerContext = () => {
    return useContext(AppContainerContext);
};

export const AppContainer = ({ children }) => {
    const [fullscreen, setFullscreen] = useState(false);
    const { isLoading } = useAppDataContext();

    return (
        <AppContainerContext.Provider
            value={{
                toggleFullscreen() {
                    setFullscreen(currentValue => !currentValue);
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundColor: theme => theme.palette.background.default,
                }}
            >
                <Box
                    sx={theme => ({
                        'display': 'flex',
                        'flexDirection': 'column',
                        'width': 480,
                        'backgroundColor': theme.palette.background.default,
                        '& > div': {
                            paddingRight: fullscreen ? 0 : theme.spacing(3),
                            paddingLeft: fullscreen ? 0 : theme.spacing(3),
                        },
                        'position': 'relative',
                        'scrollBehavior': 'smooth',
                        'height': 'inherit',
                    })}
                >
                    <Backdrop
                        open={isLoading}
                        sx={theme => ({
                            zIndex: theme.zIndex.drawer,
                            color: theme.palette.common.white,
                            position: 'absolute',
                        })}
                    >
                        <CircularProgress
                            color="primary"
                            sx={{
                                position: 'absolute',
                                top: '50vh',
                            }}
                        />
                    </Backdrop>
                    {!fullscreen && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{
                                    'py': 3,
                                    'display': 'flex',
                                    'justifyContent': 'flex-start',
                                    'alignItems': 'flex-start',
                                    '& svg': {
                                        display: 'block',
                                    },
                                    'position': 'sticky',
                                    'top': 0,
                                    'backgroundColor': 'inherit',
                                    'zIndex': 1,
                                }}
                            >
                                <Logo height={18} />
                                <Box pl={1}>
                                    <PayLaterLogo height={22} />
                                </Box>
                            </Box>
                            {/* <IconButton color="info" size="small">
                                <AccountCircleIcon />
                            </IconButton> */}
                        </Box>
                    )}
                    {children}
                </Box>
            </Box>
        </AppContainerContext.Provider>
    );
};
