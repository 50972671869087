import { useNavigate, useParams, Outlet } from 'react-router-dom';
// import { Form, applicationStatuses } from '@lendica/loan-form';
import { ApplicationForm as Form, PreviewTermsCalculator } from '@lendica/application-form';

import { useAppContainerContext } from './AppContainer';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useAppDataContext } from './AppData';

const applicationStatuses = {
    inprogress: 0,
    submitted: 1,
    approved: 2,
    declined: 3,
};

const getEnvFromHost = hostname => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX/gi.test(hostname)) {
        return 'sandbox';
    }
    if (/DEV/gi.test(hostname)) {
        return 'idev';
    }
    if (/QA/gi.test(hostname)) {
        return 'iqa';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        return 'idev';
    }
    return 'prod';
};

export const ApplicationForm = () => {
    const { billId, offerId } = useParams();
    const navigate = useNavigate();
    const { toggleFullscreen } = useAppContainerContext();
    const { loadBill, bill } = useAppDataContext();

    useEffect(() => {
        toggleFullscreen();
        if (!bill) {
            loadBill(billId);
        }
        return () => toggleFullscreen();
    }, []);

    const isShopifyCheckout = name => {
        return [
            'altametrics',
            'plum',
            'teaica',
            'black label',
            'blacklabel',
            'blackreplica',
            'vizocom',
        ].some(vendor => name.toLowerCase().includes(vendor));
    };

    const isWholesaleCheckout = name => {
        return ['stash', 'mainstem'].some(vendor => name.toLowerCase().includes(vendor));
    };

    return (
        <Box sx={{ pt: 2, pb: 4 }}>
            <Form
                env={getEnvFromHost(window?.location?.hostname)}
                onComplete={application_status => {
                    if (isShopifyCheckout(bill?.vendor_name)) {
                        switch (application_status) {
                            case applicationStatuses.approved:
                                navigate(`/${billId}/agree-on-terms/${offerId}`);
                                return;
                            case applicationStatuses.submitted:
                                navigate(`/${billId}/shop/pending`);
                                return;
                            case applicationStatuses.declined:
                                navigate(`/${billId}/shop/sorry`);
                                return;
                            default:
                                navigate(`/${billId}/pending`);
                                return;
                        }
                    } else {
                        switch (application_status) {
                            case applicationStatuses.approved:
                                if (isWholesaleCheckout(bill?.vendor_name)) {
                                    navigate(`/${billId}/agree-on-terms/${offerId}`);
                                } else {
                                    navigate(`/${billId}/confirm-bank/${offerId}`);
                                }
                                return;
                            case applicationStatuses.submitted:
                                navigate(`/${billId}/pending`);
                                return;
                            case applicationStatuses.declined:
                                navigate(`/${billId}/rejected`);
                                return;
                            default:
                                navigate(`/${billId}/pending`);
                                return;
                        }
                    }
                }}
            >
                <Outlet />
            </Form>
        </Box>
        // <Form
        //     baseURL={backendURL}
        //     onExit={() => {
        //         navigate(`/${billId}/select-terms/preview`);
        //     }}
        //     onComplete={({ application_status }) => {
        //         navigate(
        //             application_status === applicationStatuses.approved
        //                 ? `/${billId}/agree-on-terms/${offerId}`
        //                 : `/${billId}/pending`
        //         );
        //     }}
        //     onRequestError={onRequestError}
        // />
    );
};

export const OfferCalculator = () => {
    const { toggleFullscreen } = useAppContainerContext();
    useEffect(() => {
        toggleFullscreen();
        return () => toggleFullscreen();
    }, []);
    return <PreviewTermsCalculator />;
};
