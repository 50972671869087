import { Typography, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FC, useEffect } from 'react';
import axios from 'axios';

import { uploadManager } from './uploadManager';

import { getBackendURLFromHost } from '@lendica/utils';

const baseURL = getBackendURLFromHost(window!.location.hostname);

const axiosInstance = axios.create({ baseURL });

export interface DropzoneFile extends Partial<File> {
    path?: string;
    progress?: number;
    key?: string;
    error?: string;
}

export interface Accept {
    [key: string]: string[];
}

export interface DropzoneProps {
    id?: string;
    label?: string;
    helperText?: string;
    value: DropzoneFile[];
    onChange(files: DropzoneFile[]): void;
    onDrop?(files: DropzoneFile[]): void;
    api: {
        presignedUrl: string;
        presignedUrlParams?: {};
        getObjectKey?(fileName: string): string;
    };
    accept?: Accept | undefined;
    acceptHelper?: string;
    multiple?: boolean;
}

export const Dropzone: FC<DropzoneProps> = ({
    id = '',
    label,
    value,
    onChange,
    onDrop,
    api: { presignedUrl, presignedUrlParams = {}, getObjectKey },
    accept,
    acceptHelper,
    multiple,
}) => {
    // workaround to react on progress changes
    // field.value can't be used inside onDrop since might be referencing an old value
    useEffect(() => {
        if (uploadManager.size !== 0) {
            const timeout = setTimeout(() => {
                onChange([...(value ?? [])]);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [value]);

    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop: async files => {
            onDrop?.(files);
            const fileMap = new Map();

            const newFiles = files.map(file => {
                const newFile = {
                    path: file.name,
                    progress: 0,
                };
                fileMap.set(file, { file: newFile });
                const source = axios.CancelToken.source();
                uploadManager.set(newFile, source);
                let numOfPages = 1;

                const uploadFile = async () => {
                    try {
                        const s3Presigned = (
                            await axiosInstance.post<{
                                url: string;
                                fields: { [key: string]: string };
                            }>(
                                presignedUrl,
                                {
                                    object_key: getObjectKey ? getObjectKey(file.name) : file.name,
                                    object_name: file.name,
                                    bucket_name: 'lendica-sandbox',
                                    fields: {
                                        'Content-Type': file.type,
                                        'x-amz-meta-numpages': numOfPages,
                                    },
                                    conditions: [
                                        ['starts-with', '$Content-Type', ''],
                                        ['starts-with', '$x-amz-meta-numpages', ''],
                                    ],
                                    ...presignedUrlParams,
                                },
                                {
                                    cancelToken: source.token,
                                }
                            )
                        ).data;
                        const formData = new FormData();

                        for (const [key, value] of Object.entries(s3Presigned.fields)) {
                            formData.append(key, value);
                        }
                        formData.append('file', file);

                        await axiosInstance.post(s3Presigned.url, formData, {
                            onUploadProgress({ loaded, total }) {
                                fileMap.get(file).file.progress = Math.round(
                                    (loaded / total!) * 100
                                );
                            },
                            cancelToken: source.token,
                        });
                        fileMap.get(file).file.key = s3Presigned.fields.key;
                        uploadManager.delete(newFile);
                    } catch (e) {
                        if (!axios.isCancel(e)) {
                            fileMap.get(file).file.error = true;
                        }
                        uploadManager.delete(newFile);
                        return;
                    }
                };

                if (file.type === 'application/pdf') {
                    const reader = new FileReader();
                    reader.readAsBinaryString(file);
                    reader.onloadend = async () => {
                        const result = reader?.result;
                        if (typeof result !== 'string') {
                            throw new Error('Unexpected result from FileReader');
                        }
                        numOfPages = result?.match(/\/Type[\s]*\/Page[^s]/g)?.length ?? 1;
                        await uploadFile();
                    };
                } else {
                    (async function () {
                        await uploadFile();
                    })();
                }

                return newFile;
            });

            onChange([...newFiles, ...(value ?? [])]);
        },
        multiple,
    });

    return (
        <Box
            {...getRootProps()}
            sx={{
                height: 210,
                backgroundColor: '#F2F2F8',
                border: '1px dashed #D7D7E0',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
        >
            <input id={`dropzone-input-appl-${id}`} {...getInputProps()} />
            {label && (
                <Typography color="primary" variant="h5" fontWeight="bold" sx={{ mb: 1 }}>
                    {label}
                </Typography>
            )}
            <Typography color="text.disabled" variant="subtitle1">
                Drag and drop or click to upload files.
            </Typography>
            {acceptHelper && (
                <Typography color="text.disabled" variant="subtitle1">
                    {acceptHelper}
                </Typography>
            )}
        </Box>
    );
};
