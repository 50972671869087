import { Box, Button, Typography } from '@mui/material';
import { useAppDataContext } from './AppData';
import { matchHostname } from '@lendica/utils';

export const Fallback = () => {
    const { isLoading } = useAppDataContext();
    return (
        !isLoading && (
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="h5" color="text.primary" align="center" paragraph>
                    Hi there!
                </Typography>
                <Typography variant="h1" color="text.secondary" align="center" paragraph>
                    🦦
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" align="center" paragraph>
                    Are you looking for ...
                </Typography>
                <Box mt={2} display="flex" flexDirection="column" justifyContent="center">
                    <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            window?.location?.ancestorOrigins?.length > 0
                                ? (window.parent.location = `https://apply.${matchHostname()}/`)
                                : (window.location = `https://apply.${matchHostname()}/`);
                        }}
                    >
                        First time? Apply now 📝
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            window?.location?.ancestorOrigins?.length > 0
                                ? (window.parent.location = `https://portal.${matchHostname()}/`)
                                : (window.location = `https://portal.${matchHostname()}/`);
                        }}
                    >
                        Login to portal 🔐
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            window.HubSpotConversations.widget.open();
                        }}
                    >
                        Having trouble? Let's talk 💬
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            window?.location?.ancestorOrigins?.length > 0
                                ? window.parent
                                      .open('https://docsend.com/view/cvbsxpdqhzg94pig', '_blank')
                                      .focus()
                                : window
                                      .open('https://docsend.com/view/cvbsxpdqhzg94pig', '_blank')
                                      .focus();
                        }}
                    >
                        Learn more about PayLater 💡
                    </Button>
                </Box>
            </Box>
        )
    );
};
