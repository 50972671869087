import { FeatureLayout, RoundedButton } from '@lendica/components'
import { Box, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

export const ApplicationPending = () => {
    const navigate = useNavigate()

    return (
        <FeatureLayout>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    boxSizing: 'border-box',
                    px: 4
                }}
            >

                <HourglassBottomIcon color="warning" style={{ fontSize: 80 }} />
                <Typography variant="h5" sx={{ mt: 4 }}>We're reviewing your application. We will send you an email when your application is approved.</Typography>

                {/* <RoundedButton
                    size="medium"
                    startIcon={<ArrowBackIcon />}
                    sx={{ mt: 4, mx: 2 }}
                    onClick={() => navigate('/wallet')}
                >
                    Go Back
                </RoundedButton> */}
            </Box>
        </FeatureLayout >
    )
}