import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import { setAxiosInterceptors } from '@lendica/auth';
import { matchBackend } from '@lendica/utils';

const baseUrl = matchBackend(); // 'http://127.0.0.1:8000/api/v1/';

export const axiosInstance = axios.create({ baseURL: baseUrl });

export const useConfigureAxios = ({ onRequestError, onRefreshFailue }) => {
    const [isConfigured, setIsConfigured] = useState(false);
    const backendURL = useMemo(() => baseUrl, []);
    useEffect(() => {
        axios.defaults.baseURL = `${backendURL}`;
        axios.interceptors.response.use(response => response, onRequestError);
        setAxiosInterceptors(backendURL, axios, onRefreshFailue);
        setIsConfigured(true);
    }, []);
    return isConfigured;
};
