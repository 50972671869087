import { createRoot } from 'react-dom/client';
import { App } from './App';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://75314dfc2a0f49a594746c59563b089a@o4503999349522432.ingest.sentry.io/4504126011539456',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV,
});

const root = createRoot(document.getElementById('app'));

root.render(<App />);
